import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
import vuescroll from "vuescroll";
import DfContent from "@/vue/domain/content/df-content";
import Swal from "sweetalert2";
import { Printd } from "printd";
import moment from "moment";
import DfPaging from "@/vue/domain/paging/df-paging";
import DfUser from "@/vue/domain/user/df-user";
import DfProperty from "@/vue/domain/content/df-property";

const DfModalComponent = () => import(/* webpackChunkName: 'df-modal' */ "@/vue/components/df-modal/df-modal.vue");

const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfPushCardComponent = () => Utils.externalComponent2("df-push-card");
const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");

@Component({
  components: {
    DfModal: DfModalComponent,
    DfStoreCard: DfStoreCardComponent,
    DfPushCard: DfPushCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
    DfPrivateArea: () => Utils.externalComponent2("df-private-area"),
    vuescroll,
  },
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value).format(Utils.DATE_STRING_PATTER) : "";
    },
  },
})
export default class DfHeaderComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private tempValue: string = null;
  private showStoreSelectionPanel = false;
  private searchQuery: string = null;

  private readonly POSITION_HEADER: string = "HEADER";

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get headerBackgroundImageUrl(): string {
    const headerDesktop: string = Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_HEADER_DESKTOP, null);
    const headerMobile: string = Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_HEADER_MOBILE, null);

    return headerDesktop || headerMobile || `${Utils.getPublicPath()}/assets/header_mobile.png`;
  }

  get brandLogo(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO, `${Utils.getPublicPath()}/assets/logo.png`);
  }

  get extraLogo(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_EXTRA_LOGO, null);
  }

  get linkButtons(): Array<DfContent> {
    return this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "BTN");
  }

  get linkPromotionsIcon(): string {
    return `${Utils.getPublicPath()}/assets/promotions.svg`;
  }

  get linkNewsIcon(): string {
    return `${Utils.getPublicPath()}/assets/news.svg`;
  }

  get linkShoppingListIcon(): string {
    return `${Utils.getPublicPath()}/assets/shopping-list.svg`;
  }

  get linkNotificationsIcon(): string {
    return `${Utils.getPublicPath()}/assets/notifications.svg`;
  }

  get linkButtonsByPosition(): Array<DfContent> {
    return this.linkButtons.filter((linkButton: DfContent) => {
      const valueString: string = Utils.getPropertyValue(linkButton, Utils.PROPERTY_POSITION, "STRING");
      const valueList: any = Utils.getPropertyValue(linkButton, Utils.PROPERTY_POSITION, "LIST", {});
      return valueString === this.POSITION_HEADER || valueList.key === "ALL" || valueList.key === this.POSITION_HEADER;
    });
  }

  get customFontColorStyle(): string {
    const color: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_HEADER_TEXT_COLOR, "STRING");
    return color ? `color: ${color}` : `color: #FFFFFF`;
  }

  get user(): DfUser {
    return this.$store.getters.user;
  }

  get userInitials(): string {
    const firtname: string = Utils.getPropertyValue(this.user, "FIRST_NAME", "STRING");
    const lastname: string = Utils.getPropertyValue(this.user, "LAST_NAME", "STRING");

    return firtname && lastname ? `${firtname[0].toUpperCase()}${lastname[0].toUpperCase()}` : null;
  }

  @Watch("searchQuery")
  private searchQueryChanged() {
    if (this.searchQuery && this.searchQuery.length >= 3) {
      this.$root.$emit("loadStores", null, this.searchQuery);
    }
  }

  private linkButtonUrl(linkButton: DfContent): string {
    const propertyUrl: string = Utils.getPropertyValue(linkButton, Utils.PROPERTY_URL, "STRING");
    return !propertyUrl.startsWith("http") && !propertyUrl.startsWith("/") ? `/${propertyUrl}` : propertyUrl;
  }

  private linkButtonLabel(linkButton: DfContent): string {
    return Utils.getPropertyValue(linkButton, Utils.PROPERTY_BUTTON_LABEL, "STRING");
  }

  private linkButtonIcon(linkButton: DfContent): string {
    const iconFile = Utils.getPropertyImageUrl(linkButton, Utils.PROPERTY_BUTTON_ICON_IMAGE, null);
    return iconFile ? iconFile : `${Utils.getPublicPath()}/assets/btn-${linkButton.alias}.svg`;
  }

  private linkButtonOnClick(linkButton: DfContent) {
    if (this.linkButtonUrl(linkButton)) {
      Utils.isExternalLink(this.linkButtonUrl(linkButton)) ? window.open(this.linkButtonUrl(linkButton), "_blank") : this.$router.push({ path: this.linkButtonUrl(linkButton) });
    }
  }

  private customSvgColorStyle(svgUrl: string): string {
    const color: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_HEADER_TEXT_COLOR, "STRING", "#FFFFFF");
    return color ? `background-color: ${color}; -webkit-mask: url("${svgUrl}") no-repeat center; mask: url(${svgUrl}) no-repeat center;` : "";
  }

  get brandLogoHeader(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO_HEADER, null);
  }

  get brandLogoImageUrl(): string {
    return this.brandLogoHeader ? this.brandLogoHeader : this.brandLogo;
  }

  get brandLogoUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_LOGO_URL, "STRING");
  }

  get stores(): Array<DfStore> {
    return this.$store.getters.stores;
  }

  get promotions(): Array<DfPromotion> {
    return this.$store.getters.promotions;
  }

  get newsList(): Array<DfContent> {
    const storeNews: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "NWS");
    const brandNews: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "NWS");
    const news: Array<DfContent> = [...storeNews, ...brandNews].sort((contentA, contentB) => (contentA.priority <= contentB.priority ? 1 : -1));
    return news;
  }

  get showIcons(): boolean {
    return Utils.showHeaderIcons();
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  set currentStore(currentStore: DfStore) {
    this.$store.dispatch("setCurrentStore", currentStore);
    if (this.$route.params.storeAlias !== currentStore.alias) {
      if (this.$route.name === "promotion" || this.$route.name === "product") {
        this.$router.push({
          name: this.$route.name,
          params: Object.assign({}, this.$route.params, { storeAlias: currentStore.alias }),
          query: this.$route.query,
        });
      } else {
        this.$router.push({ name: "home-store", params: { storeAlias: currentStore.alias } });
      }
    }
    (<any>this.$refs.menuStore).tippy().hide();
  }

  get emptyStore(): boolean {
    return this.currentStore === null;
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  set shoppingList(shoppingList: Array<DfShoppingProduct>) {
    this.$store.dispatch("setShoppingList", shoppingList);
  }

  get storedPushesList(): Array<any> {
    return this.$store.getters.storedPushesList;
  }

  set storedPushesList(storedPushesList: Array<any>) {
    this.$store.dispatch("setStoredPushesList", storedPushesList);

    if (storedPushesList.length > 0) {
      Utils.localStorageSetItem("df-pushes-list", JSON.stringify(this.storedPushesList));
    } else {
      Utils.localStorageRemoveItem("df-pushes-list");
    }
  }

  get pushesList(): Array<DfContent> {
    const storePush: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "PSH");
    const brandPush: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "PSH");
    const pushesList: Array<DfContent> = [...storePush, ...brandPush].sort((contentA: DfContent, contentB: DfContent) => {
      const dateA: string = Utils.getPropertyValue(contentA, Utils.PROPERTY_DATE, "DATE");
      const dateB: string = Utils.getPropertyValue(contentB, Utils.PROPERTY_DATE, "DATE");
      return dateA < dateB ? 1 : -1;
    });

    return pushesList.filter((push: DfContent) => {
      const stored: any = this.storedPushesList.find((storedPush: any) => storedPush.alias === push.alias);
      return stored ? !stored.deleted : true;
    });
  }

  get pushesNumber(): number {
    return this.pushesList.filter((push: DfContent) => this.storedPushesList.findIndex((storedPush: any) => storedPush.alias === push.alias) === -1).length;
  }

  get geolocationEnabled(): boolean {
    return this.$store.getters.geolocationEnabled;
  }

  set geolocationEnabled(geolocationEnabled: boolean) {
    this.$store.dispatch("setGeolocationEnabled", geolocationEnabled);
  }

  get enablePrivateArea(): boolean {
    const rule01: boolean = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_PRIVATE_AREA_DISABLED, "BOOLEAN", false);
    const rule02: boolean = Utils.enablePrivateArea();
    return !rule01 && rule02;
  }

  get showLogin(): boolean {
    return this.$store.getters.showLogin;
  }

  @Watch("showLogin")
  private showLoginChange() {
    if (<any>this.$refs.tippyPrivateArea && this.showLogin && (<any>this.$el).checkVisibility()) {
      this.$store.dispatch("setShowLogin", false);
      (<any>this.$refs.tippyPrivateArea).tippy().show();
    }
  }

  private toggleSelection(shoppingProduct: DfShoppingProduct) {
    this.shoppingList = this.shoppingList.map((product: DfShoppingProduct) => {
      product.checked = product.description === shoppingProduct.description ? !product.checked : product.checked;
      return product;
    });
  }

  private removeFromList(shoppingProduct: DfShoppingProduct) {
    const index: number = this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === shoppingProduct.description);
    if (index !== -1) {
      this.shoppingList = this.shoppingList.slice(0, index).concat(this.shoppingList.slice(index + 1, this.shoppingList.length));
    }
  }

  private addToList() {
    if (this.tempValue) {
      this.shoppingList = [new DfShoppingProduct(this.tempValue, false), ...this.shoppingList];
      Utils.setAnaliticsTraker({
        event: "add_product_to_shopping_list",
        origin: "smt-digitalflyer-ui",
        productAlias: "",
        productBrand: "",
        productCategory: "",
        productCode: "",
        productDescription: this.tempValue,
        productDiscountType: "",
        productFeatured: "",
        productInitialPrice: "",
        productPrice: "",
        productTitle: "",
        promotionAlias: "",
        promotionBeginDate: "",
        promotionCode: "",
        promotionDescription: "",
        promotionEndDate: "",
        storeAlias: this.currentStore.alias,
        storeCode: this.currentStore.code,
        storeName: this.currentStore.name,
      });
      this.tempValue = null;
    }
  }

  beforeMount() {
    const shoppingList: any = Utils.localStorageGetItem("df-shopping-cart");
    if (shoppingList) {
      this.$root.$emit("checkCart", JSON.parse(shoppingList));
    }
  }

  private changeCurrentStore(store: DfStore) {
    this.currentStore = store;
    this.$root.$emit("modules-modal.close", "df-modal.id.store-selection");

    if (store) {
      Utils.setAnaliticsTraker({
        event: "change-store",
        origin: "smt-digitalflyer-ui",
        storeAlias: this.currentStore.alias,
        storeCode: this.currentStore.code,
        storeName: this.currentStore.name,
      });
    }
  }

  private isCurrentStore(store: DfStore): boolean {
    return this.currentStore ? this.currentStore.code === store.code : false;
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private openStoreSelectionPanel(open: boolean) {
    this.showStoreSelectionPanel = open;
  }

  private openStoreInfoPanel() {
    Utils.setAnaliticsTraker({
      event: "show_store_info",
      origin: "smt-digitalflyer-ui",
      storeAlias: this.currentStore.alias,
      storeCode: this.currentStore.code,
      storeName: this.currentStore.name,
    });
  }

  private resetSearchQuery() {
    this.searchQuery = null;
    this.$root.$emit("loadStores");
  }

  private updateStoredPushesList() {
    this.pushesList.forEach((push: DfContent) => {
      const newPush: boolean = this.storedPushesList.findIndex((storedPush: any) => storedPush.alias === push.alias) === -1;
      if (newPush) {
        this.storedPushesList = [...this.storedPushesList, { alias: push.alias, deleted: false }];
      }
    });
  }

  private removeShoppingList() {
    Swal.fire({
      title: this.$t("df-card-shopping-list.delete.title").toString(),
      text: this.$t("df-card-shopping-list.delete.body").toString(),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continua",
    }).then((result) => {
      if (result.value) {
        this.shoppingList = [];
      }
    });
  }

  private onClipboardSuccess(data: any) {
    const icon = data.event.target;
    const button = data.event.target.parentNode;
    if (icon != null) {
      icon.classList.toggle("far");
      icon.classList.toggle("fa-copy");
      icon.classList.toggle("fas");
      icon.classList.toggle("fa-check-double");
      button.setAttribute("title", "Copiato");
      setTimeout(() => {
        icon.classList.toggle("far");
        icon.classList.toggle("fa-copy");
        icon.classList.toggle("fas");
        icon.classList.toggle("fa-check-double");
        button.setAttribute("title", "Copia");
      }, 2000);
    }
    data.event.preventDefault();
  }

  get copyShoppingListValue() {
    let bodyContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}\n\n`;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return bodyContent;
  }

  private printShoppingList() {
    const d = new Printd();
    const cssText = `
      .df-shopping-list-item-check,
      button {
        display: none;
      }

      .df-shopping-list-item {
        margin-bottom: 1rem;
      }
    `;
    d.print(document.getElementById("printable-area-shopping-list"), [cssText]);
  }

  get messengerShoppingList(): string {
    let bodyContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}\n\n`;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return `https://m.me/?text=${encodeURIComponent(bodyContent)}`;
  }

  get whatsappShoppingList(): string {
    let bodyContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}\n\n`;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return `https://wa.me/?text=${encodeURIComponent(bodyContent)}`;
  }

  get emailShoppingList(): string {
    const subjectContent = `${this.$t("df-card-shopping-list.label")} - ${this.$options.filters!.dateFormat(Date.now())}`;
    let bodyContent = ``;
    [...this.shoppingList].reverse().forEach((product) => {
      bodyContent += `${product.description}\n\n`;
    });
    return `mailto:?subject=${subjectContent}&body=${encodeURIComponent(bodyContent)}`;
  }

  get storesPaging(): DfPaging {
    return this.$store.getters.storesPaging;
  }

  private toggleGeolocation() {
    this.geolocationEnabled = !this.geolocationEnabled;
    Utils.localStorageSetItem(Utils.LOCAL_STORAGE_GEO_LOCALIZATION, this.geolocationEnabled.toString());
  }

  private handleStoresScroll(vertical: any, horizontal: any, nativeEvent: any) {
    if (Math.ceil(nativeEvent.target.scrollTop) + nativeEvent.target.clientHeight >= nativeEvent.target.scrollHeight) {
      if (this.storesPaging && !this.storesPaging.last) {
        this.storesPaging.number++;
        this.$root.$emit("loadStores", this.storesPaging);
      }
    }
  }

  private close() {
    if (<any>this.$refs.tippyPrivateArea) {
      (<any>this.$refs.tippyPrivateArea).tippy().hide();
    }
  }

  private trackShoppingInfo() {
    Utils.setAnaliticsTraker({
      event: "show_shoppinglist",
      origin: "smt-digitalflyer-ui",
      storeAlias: this.currentStore.alias,
      storeCode: this.currentStore.code,
      storeName: this.currentStore.name,
    });
  }
}
